#checkoutPage{
    min-height: 63vh;
    background: var(--c1);
    box-sizing: border-box;
}


#checkoutPage .space{
    padding: 2rem;
    background: var(--c1);
}