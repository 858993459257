body {
    --c0: rgb(255,244,239);
    --c1: rgb(236,224,209);
    --c2: rgb(219,193,172);
    --c3: rgb(150,114,89);
    --c4: rgb(99,72,50);
    --c5: rgb(56,34,15);
}

/* ======= Carousel  ==========  */

html {
    scroll-behavior: smooth;
  }

#mainCarousel, #ourCreationCarousel{
    position: relative;
    background: var(--c1);
    box-sizing: border-box;
}

#mainCarousel .carousel-container{
    height: 93vh;
}

#mainCarousel  .carousel-container ul li{
    position: relative;
}


#mainCarousel .carouselOverlayHeader{
    position: absolute;
    top: 0vh;
    left: 5vw;
    color: var(--c1);
    width: 90vw;
    font-size: clamp(1.5rem, 7vw, 5rem);
    margin-bottom: 0;
}

#mainCarousel .carouselOverlayText{
    position: absolute;
    top: 260px;
    left: 8vw;
    color: var(--c1);
    width: 60vw;
    font-size: clamp(1.3rem, 4vw, 2rem);
}

#mainCarousel .actButton{
    position: absolute;
    bottom: 8vh;
    width: 100%;
    color: var(--c1);
    display: flex;
    margin: 0;
    justify-content: center;
}

#mainCarousel .carousel-container .carouselSlideContainer img{
    width: 100vw; 
    height: 94vh;
    object-fit: cover;
    filter: brightness(70%);
}

#ourCreationCarousel{
    height: 1100px;
    padding: 2rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ourCreationCarousel .carousel-container{
   padding: 2rem 0;
    width: 95%; 
    height: 800px;
}

#ourCreationCarousel .carousel-container .carouselSlideContainer img{
    width: 100vw; 
    height: 94vh;
    object-fit: cover;
}

#ourCreationCarousel .carousel-container h1{
    font-size: clamp(1.5rem, 2.5vw, 2.8rem);
    text-wrap: nowrap;
}


#ourCreationCarousel .carouselOverlayHeader{
    color: var(--c5);
    width: 90vw;
    font-size: clamp(1.5rem, 7vw, 5rem);
    margin-top: 1rem;
}

#ourCreationCarousel .carouselOverlayText{
    color: var(--c5);
    width: 80vw;
    font-size: clamp(1.3rem, 4vw, 2rem);
    padding-left: 3vw;
}

#ourCreationCarousel .actButton{
    bottom: 2vh;
}

#ourCreationCarousel .react-multi-carousel-dot-list{
    position: absolute;
    bottom: 0px;
}


/* ======= sections ==========  */

#LP_AboutUs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    align-items: center; 
}

#LP_AboutUs .LP_SectionTitle{
    box-sizing: border-box;
    width: 100%;
    Color: var(--c1);
    padding: 3rem;


}

#LP_AboutUs{
    background-image:  url('../../images/K0002_13.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

#LP_InStock{
    background: linear-gradient(176deg, rgb(236,224,209) 0%, rgb(99,72,50) 80%, rgba(56,34,15,1) 130%);

}

#LP_BYO{
    background-image:  url('../../images/Keyboard0_2.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

/* ======   card  ========= */

.LP_CardContainer {
    display: flex;
    flex-direction: column;
    width: 60vw;
    background-color: white;
    margin: 6rem 0;
    background-color: var(--c1)
}

.LP_CardContainer img{
    object-fit: cover;
    height: 40vh;
}

.LP_CardContainer .cardText{
    /* background: linear-gradient(176deg, var(--c0) 0%, rgb(99,72,50) 80%, rgba(56,34,15,1) 130%); */
    /* background: linear-gradient(176deg, var(--c0) 20%, var(--c4) 100%); */
    background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%);
    color: var(--c4);
    min-height: 40vh;
    font-size: 1.5rem;
    padding: 2rem;

}


.actButton{
    margin-top: 6vh;
    margin-left: -10vw;
}

#ourCreationCarouselBtn{
    margin: 3vh 0 2vh 0;
}

   /* button */


.btn {
    /* font-size: calc(1vw + 1vh); */
    font-size: clamp(1.6rem, calc(1vw + 1vh), 1.5rem);
    font-weight: bold;
    background: var(--c0);
    border: none;
    text-transform: uppercase;
    transition: 0.5s ease;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: var(--c5);
  }
  
  .btn::before {
    content: "";
    position: absolute;
    left: -6px;
    bottom: 0;
    height: 0.7vh;
    width: 0;
    background-color: rgba(99,72,50,1);
    transition: 0.3s ease;
  }
  
  .btn:hover {
    color: var(--c1);
    transition-delay: 0.3s;
    background: transparent;
  }
  
  .btn:hover::before {
    width: 100%;
  }
  
  .btn::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color:  rgba(99,72,50,1);
    transition: 0.3s ease;
    z-index: -1;
  }

  .btn:hover::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }

  #ourCreationsBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 10vh;
  }

  #ourCreationsBtn .actButton{
    margin: 0;
    padding: 0 5rem;
    position: relative;
  }

/* New Keyboard Section */
#LP_NewKeyboard{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start; 
    flex-direction: row;
    flex-wrap: wrap;
    background: linear-gradient(176deg, var(--c0) 20%, rgb(99,72,50) 100%);
    color: var(--c5);

}
#LP_NewKeyboard h1{
    width: 100%;
    padding-left: 3rem;
    margin-bottom: 0;
}
#LP_NewKeyboard p{
    width: 100%;
    padding-left: 3rem;
    margin-top: 1rem;
    margin-left: 3rem;
    font-size: 1.5rem;
}

#LP_NewKeyboard .card{
    min-width: 300px;
    margin: 5%;
    transition: all 0.2s linear 0s;
    /* -webkit-box-shadow: 0px 12px 16px 5px rgba(227,223,215,0.7);
    -moz-box-shadow: 0px 12px 16px 5px rgba(227,223,215,0.7);
    box-shadow: 0px 10px 14px 2px rgba(227,223,215,0.7); */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    z-index: 1;
    font-size: 1.5rem;
    
    /* position: relative; */
}

#LP_NewKeyboard .card img{
    width: 20vw;
    height: 20vh;
    object-fit: cover;
    /* background: linear-gradient(176deg, rgb(236,224,209) 0%, rgb(99,72,50) 80%, rgba(56,34,15,1) 130%); */
    /* -webkit-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
    -moz-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
    box-shadow: 0px 8px 10px 2px var(--c4);  */
}

#LP_NewKeyboard img:hover{
    /* width: 100%;
    border-radius: 5px; */
     -webkit-box-shadow: 10px 10px 60px 30px rgba(0,0,0,1);
    -moz-box-shadow: 10px 10px 60px 30px rgba(0,0,0,1);
    box-shadow: 0px 7px 12px 0px rgba(99,72,50,0.4);
    /* border: 2px solid white; */

}

#LP_NewKeyboard .card:hover{
    cursor: pointer;
}

#LP_NewKeyboard .card ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    transition: all 0.5s;
    color: var(--c5);
}

#LP_NewKeyboard .card ul img{
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.2rem 2.5rem 0.2rem 0.2rem;
    padding: 0.1rem 0;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

}


/* 3 gateways sections */
.gatewayBG{
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0,0,0,1); */
    z-index: 0;
}

#gatewayInStock{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(176deg, var(--c1) 0%, rgba(56,34,15,1) 130%);
    /* background-color: var(--c1); */
    transition: all 1s;
    position: relative;
    color: var(--c5);
    height: 500px;
}

/* #gatewayInStock h1, #gatewayBYO h1, #gatewayComponents h1, #gatewayAboutUs h1 {
    z-index: 2;
    font-size: 3rem;
    padding-left: 2rem;
} */

#gatewayInStock:hover{
    background-image:  url('../../images/K0005_6.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left -2vw;
    color: var(--c1);
    cursor: pointer;

}

/* #gatewayInStock:hover .gatewayBG, #gatewayBYO:hover .gatewayBG, #gatewayComponents:hover .gatewayBG{
    background-color: rgba(0,0,0,0.5);
} */

#gatewayBYO, #gatewayAboutUs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(176deg, rgb(99,72,50) 0%, rgba(56,34,15,1) 130%);
    color: var(--c1);
    position: relative;
    transition: all 1s;
    /* background-color: var(--c5); */
    height: 500px;
}

#gatewayBYO:hover{
    background-image:  url('../../images/BYO_BG.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left -2vw;
    cursor: pointer;
}

#gatewayComponents{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(176deg, var(--c1) 0%, rgba(56,34,15,1) 130%);
    /* background-color: var(--c1); */
    position: relative;
    transition: all 1s;
    color: var(--c5);
    height: 500px;
}
#gatewayComponents:hover{
    background-image:  url('../../images/switches_001_7.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--c1);
    background-position: left -2vw;
    cursor: pointer;
}

#gatewayInStock .gatewayNL, #gatewayBYO .gatewayNL, #gatewayComponents .gatewayNL, #gatewayAboutUs .gatewayNL{
    z-index: 2;
    font-size: 5rem;
    /* padding-left: 2rem; */
    position: absolute;
    width: 98%;
    height: 100%;    
    z-index: 2;
    text-decoration: none;
    text-align: center;
    color: rgba(255,244,239,0.3);
    line-height: 500px;
    height: 500px;

}

#gatewayAboutUs:hover{
    background-image:  url('../../images/K0001_3.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left -2vw;
    cursor: pointer;
}

/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
    .LP_CardContainer {
        width: 70vw;
    }
  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
    .LP_CardContainer {
        width: 80vw;
    }
  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {
    .LP_CardContainer {
        width: 70vw;
    }
  }


/* mobile */
@media screen and (max-width: 464px) {
    
    #mainCarousel #mainCarousel .carousel-container{
        height: 93vh;
        width: 100vw;
    }

    #mainCarousel .carouselOverlayText{
        width: 90vw;
        top: 15vh;
    }
    
    #mainCarousel .actButton{
        bottom: 7vh;
        width: 100%;
    }

    .actButton{
        margin: 2rem 0 0 0;
    }

    .btn {
        font-size: 1rem;
        margin:0;
        padding: 1rem 0.5rem;
    }

    .LP_CardContainer .cardText h1{
        font-size: calc(4vw + 3.5vh)
    }
    
    #ourCreationCarousel{
        height: 850px;
    }
    
    #ourCreationCarousel .carousel-container{
        height: 600px;
    }


    #ourCreationCarousel .carouselOverlayHeader{
        top: 2vh;
    }

    #ourCreationCarousel .carouselOverlayText{
        top: 12vh;
    }

    .LP_CardContainer {
        width: 90vw;
    }

    .LP_CardContainer p {
        font-size: calc(1.5vw + 1.5vh);
    }

    #LP_NewKeyboard h1{
        padding-left: 2rem;
    }
    

    #LP_NewKeyboard p{
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 1rem;
        margin-left: 1rem;
        font-size: 1.5rem;
    }

    #LP_NewKeyboard{
        justify-content: center; 
    }

    #LP_NewKeyboard .card{
        min-width: 95vw;
        align-items: center;
        /* margin: 5%; 
        transition: all 0.2s linear 0s;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 1;
        font-size: 1.5rem; */

    }
    
    #LP_NewKeyboard .card img{
        width: 95vw;
        height: 20vh;
        object-fit: contain;
        /* background: linear-gradient(176deg, rgb(236,224,209) 0%, rgb(99,72,50) 80%, rgba(56,34,15,1) 130%); */
        /* -webkit-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
        -moz-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
        box-shadow: 0px 8px 10px 2px var(--c4);  */
    }

    #ourCreationsBtn .actButton{
        padding: 0 1rem;
      }
    

    #gatewayComponents, #gatewayInStock, #gatewayBYO, #gatewayAboutUs{
        font-size: 1rem ;
        padding-left: 1rem;
    }

    .gatewayBG{
        width: 96vw;
    }

    #gatewayComponents .gatewayNL, #gatewayInStock .gatewayNL, #gatewayBYO .gatewayNL, #gatewayAboutUs .gatewayNL{
        width: 96vw;
        font-size: 3rem;
    }

    /* #gatewayInStock h1, #gatewayBYO h1, #gatewayComponents h1, #gatewayAboutUs h1 {
        padding-left: 1rem;
    
    } */
/* ==== backgrounds ===== */

#gatewayInStock:hover, #gatewayBYO:hover, #gatewayComponents:hover, #gatewayAboutUs:hover,
#gatewayInStock:active, #gatewayBYO:active, #gatewayComponents:active, #gatewayAboutUs:active{
    background-position: left 0vw;
}
}