@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Life+Savers:wght@400;700;800&family=Protest+Guerrilla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&family=Imbue:opsz,wght@10..100,100..900&family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn+SC:wght@400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap');


/*
"Protest Guerrilla", sans-serif;
"EB Garamond", serif;
"Life Savers", serif;
"Fraunces", serif;
"Bebas Neue", sans-serif;
"Patua One", serif;
"Imbue", serif;
"Bona Nova SC", serif;
"Bowlby One", sans-serif;

*/

body{
  font-family: "Patua One", serif;
  font-weight: 300;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
