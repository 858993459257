#ourCreationPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
    box-sizing: border-box;
    background-color: var(--c1);
}

#ourCreationPage .IS_SectionTitle{
    Color: var(--c1);
    padding: 1rem;
    height: 50vh;
    background-image:  url('../../images/K0002_4.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

#ourCreationPage .IS_SectionTitle p, #ourCreationPage .IS_SectionTitle h1{
    Color: var(--c1);
    padding: 0 0 0 1rem;
    margin: 0;
}

#ourCreationPage .IS_SectionTitle p{
    position: relative;
    top: 20px;
    padding: 0;
    font-size: clamp(1.6rem, 2vw, 2rem);
    color: var(--c1);
    width: 50vw;
    /* background-color: rgba(56,34,15,0.72);
    -webkit-box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9);
    -moz-box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9);
    box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9); */
    /* box-shadow: inset -4px -11px 151px 80px rgba(56,34,15,0.9); */
    border-radius: 5%;
    text-align: justify;
}

#ourCreationPage .IS_SectionTitle h1{
    font-size: clamp(3rem, 7vw, 7rem);
}

#ourCreationPage .IS_SectionTitle h2{
    font-size: clamp(2rem, 4vw, 4rem);
    margin: 1rem 1rem 1rem 3rem;
}



  /* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){

}

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {

}

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {

}


/* mobile */
@media screen and (max-width: 464px) {
    #ourCreationPage .IS_SectionTitle p{
        left: 0.5rem;
        color: var(--c1);
        font-size: clamp(1.2rem, 2vw, 2rem);
        width: 90vw;
        background-color: rgba(56,34,15,0);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

}