/*  {
    --c0: rgb(255,244,239);
    --c1: rgb(236,224,209);
    --c2: rgb(219,193,172);
    --c3: rgb(150,114,89);
    --c4: rgb(99,72,50);
    --c5: rgb(56,34,15);
} */

.footerContainer{
    width: 100%;
    height: 30vh;
    /* background: linear-gradient(176deg, rgb(236,224,209) 0%, rgb(99,72,50) 80%, rgba(56,34,15,1) 130%); */
    background: linear-gradient(176deg, rgb(99,72,50) 0%, rgba(56,34,15,1) 130%);
    color: rgb(236,224,209);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.footerContainer .footerSection{
    height: 100%;
    width: 30vw;
    font-size: 1rem;    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

}

.footerContainer .navlink{
    color: rgb(236,224,209);
    text-decoration: none;
    display: block;
    padding: 1vh 0;
}

.footerContainer .footerSection .navlink img{
    height: 7vh;
    padding: 1vh 0;
}

.subFooter{
    font-size: 1rem;
    position: absolute;
    bottom: 0.1vh;
    border-top: 0.8px solid rgba(236,224,209,0.8);
    width: 90%;
    display: flex;
    justify-content: center;
}

#mailto {
    text-decoration: none;
    color: rgb(236,224,209);
}



/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
}

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
}

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {
}


/* mobile */
@media screen and (max-width: 464px) {
    .footerContainer{
        height: 75vh;

        
    }

    .footerContainer .footerSection{
        width: 90vw;
        height: 30%;
    }

    .footerContainer .footerSection h1{
        margin-bottom: 0;
    }

    .subFooter{
        border-top: 0.2px solid rgba(236,224,209,0.5);
    }
}