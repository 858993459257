main {
    box-sizing: border-box;   
    margin: 0;
    padding: 0; 
    /* background-image:  url('../../images/background_iStock.jpeg'); */
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background: linear-gradient(170deg, rgb(30,30,30) 6.8%, rgba(56,34,15,1) 110%);
}

body{
    font-family: "Ubuntu", sans-serif;
    font-size: 4vh;  
    --mainCol1: rgba(251,253,255,1);
    --mainCol2: rgba(243,245,240,1);
    color: rgba(75,75,75,1);
}



/*====   Carousel   =====*/

main {
    width: 100%;
}





/*====   in Page NavBar   =====*/
#IPNBContainer{
    width: 99vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;
    margin: auto;
    background: rgba(0,0,0,0.8);
    padding-top: 3rem;
    position: relative;
}

.inPageNavDescription{
    display: block;
    color: white;
    /* position: absolute; */
}

#IPNBInStockBtn:hover #inPageNavDescriptionInStock{
    display: block;
}

.btn {
    font-size: 2rem;
    background: transparent;
    border: none;
    padding: 1em 1.5em;
    color: white;
    text-transform: uppercase;
    transition: 0.5s ease;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    z-index: 0;
    margin: 2rem;    
  }
  
  .btn::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: rgba(99,72,50,1);
    transition: 0.3s ease;
  }
  
  .btn:hover {
    color: rgba(236,224,209,1);
    transition-delay: 0.3s;
  }
  
  .btn:hover::before {
    width: 100%;
  }
  
  .btn::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color:  rgba(99,72,50,1);
    transition: 0.3s ease;
    z-index: -1;
  }

  .btn:hover::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }




