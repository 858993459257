/* --c0: rgb(255,244,239);
--c1: rgb(236,224,209);
--c2: rgb(219,193,172);
--c3: rgb(150,114,89);
--c4: rgb(99,72,50);
--c5: rgb(56,34,15); */

#BYOPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, var(--c2) 2%, var(--c5) 60%); */
    box-sizing: border-box;
    background: var(--c1);
    background-image:  url('../../images/BYO_BG.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--c1);
}



/* #BYOPage .bgBlurr{
    background-color: rgba(56,34,15,0.8); 
    background: radial-gradient(circle, rgba(56,34,15,0.4) 70%, rgba(0,0,0,0.9) 90%);
        background: linear-gradient(180deg, rgba(0,0,0,0.6) 10%, rgba(32, 20, 9, 0.8) 100%);
    background-color: rgba(0,0,0,0.6);
    backdrop-filter: blur(4px);
    backdrop-filter: saturate(50%) blur(3px);
     backdrop-filter: drop-shadow(4px 4px 10px blue);
} */

#BYOPage .sectionTitle{
    padding: 1rem;
}

#BYOPage .sectionTitle p, #BYOPage .sectionTitle h1{
    Color: var(--c2);
    padding: 0 0 0 2rem;
    margin: 0;
}

#BYOPage .sectionTitle h2{
    padding: 0 0 2rem 5rem;
    font-size: clamp(1.6rem, 2vw, 2rem);
    color: var(--c2);
    text-align: justify;
}

#BYOPage .sectionTitle p{
    padding: 0 2rem 2rem 7rem;
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    text-align: justify;
}

#BYOPage .sectionTitle h1{
    font-size: clamp(3rem, 7vw, 7rem);
}

.BYOMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer .componentsSelector .transitionTarget{
    transition: all 1s;
}

.BYOMainContainer .detailsVisible{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 38vh;
    object-fit: cover;
    position: sticky;
    top: 7vh;
    z-index: 5;
    align-self: center;
    /* transform: translateY(0px); */
    opacity: 1;
}



.BYOMainContainer .detailsHidden, .BYOMainContainer .detailsHidden p, .BYOMainContainer .detailsHidden img {
    height: 0;
    opacity: 0;

}

.BYOMainContainer .detailsVisible .choiceText{
    color: var(--c1);
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
}

.BYOMainContainer .BYOselectionContainer .componentsSelector .detailsVisible .bigImg{
    width: 70vw;
    height: 40vh;
    object-fit: contain;
    padding: 1rem 0rem;
}

.BYOMainContainer .BYOselectionContainer{
    width: 100%;
    /* min-height: 50vh; */
    display: flex;
    justify-content: center;

}

/* .BYOMainContainer .BYOselectionContainer .componentImg{
    height: 33vh;
    width: 50vw;
    padding: 4rem 2rem 2rem 2rem;
    object-fit: cover;
} */

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer, 
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer article{
    /* height: 38vh; */
    width: 100%;
    overflow: auto;
    padding: 0rem 0 0 0;
    margin: 0rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    scrollbar-color: var(--c4) rgba(219,193,172,0);
    scrollbar-width: thin;
}

/* .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer article{
    width: 100%;
} */

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer .componentsSelector{
    color: var(--c2);
    width: 80%;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 5rem 0rem;
    margin: 1rem;
    /* -webkit-box-shadow: 0px 0px 8px 2px rgba(99,72,50,0.5);
    -moz-box-shadow: 0px 0px 8px 2px rgba(99,72,50,0.5);
    box-shadow: 0px 0px 8px 2px rgba(236,224,209,0.3); */
    /* background: rgba(236,224,209,0.1); */
    /* background-color: rgba(99,72,50,0.1); */
    /* background-color: rgba(81, 52, 27, 0.206); */
    background-color: rgba(0,0,0,0.4);
    border: 1px solid rgba(250,250,250,0.2);
    border-radius: 4px;
}

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer .noBG{
    background-color: rgba(99,72,50,0);
}


.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer .componentsSelector img{
    width: 2rem;
    margin-right: 1rem;
}

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    overflow-x: auto;
}

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .fs {
    justify-content: flex-start;
}


.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionButton  {
    background-color: rgba(99,72,50,1);
    color: black;
    font-size: calc(0.7vw + 0.7vh);
    font-weight: bold;
    border: none;
    text-transform: uppercase;
    transition: 0.5s ease;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    margin: 1rem 1rem;
    padding: 0.5rem;
    border-radius: 1px;
    height: 3rem;
    text-wrap: nowrap;
    border: 1px solid var(--c5);
    display: flex;
    align-items: center;
  }
  
  .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
  .componentsSelector .componentOptions .componentOptionButton:before {
    content: "";
    position: absolute;
    left: -4px;
    bottom: 0;
    height: 0.2vh;
    width: 0;
    background-color: var(--c2);
    transition: 0.3s ease;
  }
  
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionButton:hover, 
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionSelected{
    color: var(--c1);
    transition-delay: 0.3s;
    background: rgba(99,72,50,0.6);
  }
  
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionButton:hover::before, 

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionSelected::before {
    width: 95%;
  }
  
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionButton::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color:  rgba(99,72,50,1);
    transition: 0.3s ease;
    z-index: -1;
  }

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionButton:hover::after, 
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector .componentOptions .componentOptionSelected::after{
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }

  .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector textarea, 
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector input{
    width: 60vw;
    min-height: 5vh;
    background: transparent;
    color: var(--c2);
    font-size: 1rem;
    border: none;
    font-family: inherit;
    margin: 1.5rem 0 1rem 0;
    /* -ms-overflow-style: none;  IE and Edge */
    /* scrollbar-width: none;  Firefox */
    text-align: center;
}

/* Change the white to any color */
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector input:-webkit-autofill,
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector input:-webkit-autofill:hover, 
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector input:-webkit-autofill:focus, 
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--c2);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #14131300;
}








.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector textarea::placeholder,
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector input::placeholder{
    color: var(--c2);
    font-family: inherit;
}

.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector textarea:focus, 
.BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
.componentsSelector input:focus{
    outline: none !important;
    border: 1px solid rgba(236,224,209,0.1)
    /* -webkit-box-shadow: 0px 0px 8px 2px rgba(99,72,50,0.5);
    -moz-box-shadow: 0px 0px 8px 2px rgba(99,72,50,0.5);
    box-shadow: 0px 0px 8px 4px black; */
}

#componentOptionsFinalButton{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 1rem 0 1rem 0;
    margin-bottom: 3rem;
}

#componentOptionsFinalButton .componentOptionButton{
    background-color: var(--c2);
    font-size: calc(1.2vw + 1.2vh);
    padding: 1.2rem 2rem;
    border-radius: 1px;
    height: 4rem;
    border-radius: 4px;
}


#componentOptionsFinalButton  .componentOptionButton:before {
    content: "";
    position: absolute;
    left: -6px;
    bottom: 0;
    height: 0.5vh;
    width: 0;
    background-color: var(--c3);
    transition: 0.2s ease;
  }
  
  #componentOptionsFinalButton .componentOptionButton:hover {
    color: var(--c1);
    transition-delay: 0.2s;
    background: rgba(99,72,50,0.6);
  }
  
  #componentOptionsFinalButton .componentOptionButton:hover::before {
    width: 95%;
  }


  /* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){

  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {

  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {

  }


/* mobile */
@media screen and (max-width: 464px) {
    #BYOPage .sectionTitle{
        padding: 1rem 0rem;
        margin-bottom: 2rem;
    }

    #BYOPage .sectionTitle p, #BYOPage .sectionTitle h1{
        padding: 0 0 0 1rem;
    }

    #BYOPage .sectionTitle h2{
        padding: 0 2rem 2rem 3rem;
    }
    
    #BYOPage .sectionTitle p{
        padding: 0 1rem 2rem 1rem;
    }

    .BYOMainContainer .detailsVisible{
        top: 8vh;
        width: 95%;
        padding: 0;
        height: 15vh;
    }
    
    
    .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
    .componentsSelector{
        width: 90%;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        margin-left: 0;
        margin-right: 0;
        background-color: rgba(0,0,0,0.8);

    }

    .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
    .componentsSelector .componentOptions {
        flex-direction: column;
        overflow: scroll;
        height: 15vh;
        align-items: center;
        justify-content: flex-start;
    }

    .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
    .componentsSelector .componentOptions .componentOptionButton  {
        font-size: calc(1.3vw + 1.3vh);
        margin: 0.4rem 1rem;
        padding: 0.5rem;
        height: 3.5rem;
        display: inline;
        text-wrap: wrap;
        text-align: center;
        /* max-width: 80%; */
        width: 75%;
    }

    .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
    .componentsSelector textarea, 
    .BYOMainContainer .BYOselectionContainer .componentsSelectorContainer 
    .componentsSelector input{
        width: 98%;
        height: 10vh;
    }

    #componentOptionsFinalButton{
        width: 92%;
        padding: 2rem 0;
        margin: 0 0 1rem 0;
    }


    #componentOptionsFinalButton .componentOptions{
        height: 100%;
    }

    .BYOMainContainer .BYOselectionContainer .componentsSelector .detailsVisible .bigImg{
        width: 80vw;
        height: 40vw;
        object-fit: cover;
        padding: 1rem 1rem;
        margin-left: 1rem;
    }
    

  }